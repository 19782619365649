/**
 * @deprecated This Material UI icon is deprecated. Please use an icon from our design system instead.
 * Contact the design team for the appropriate replacement icon.
 */
import * as React from 'react';
import createMuiIcon from '../createMuiIcon';

export default createMuiIcon(
    
  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
, 'ChevronRight');
