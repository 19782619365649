import * as React from 'react';
import cx from 'classnames';

import styles from './MuiIcon.module.scss';

export interface MuiIconProps extends React.SVGProps<SVGSVGElement> {
    children?: React.ReactNode;
    className?: string;
}

const MuiIcon = ({ children, className, ...other }: MuiIconProps) => {
    return (
        <svg
            className={cx('MuiSvgIcon-root', styles.icon, className)}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden={true}
            {...other}
        >
            {children}
        </svg>
    );
};

export default MuiIcon;
