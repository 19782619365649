import React from 'react';
import MuiIcon, { MuiIconProps } from './MuiIcon';

export default function createMuiIcon(path: React.ReactNode, displayName: string) {
    const Component = (props: MuiIconProps) => {
        return <MuiIcon {...props}>{path}</MuiIcon>;
    };

    if (process.env.NODE_ENV !== 'production') {
        Component.displayName = `${displayName}Icon`;
    }

    return React.memo(Component);
}
