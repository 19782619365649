import { cloneDeep, find, indexOf, isEmpty } from 'lodash';
import { getId } from '@app/utils';
import * as Main from 'reducers/main';

export const SALES_MATERIAL_FACT_QUESTIONS_REQUEST = 'SALES_MATERIAL_FACT_QUESTIONS_REQUEST';
export const SALES_MATERIAL_FACT_QUESTIONS_SUCCESS = 'SALES_MATERIAL_FACT_QUESTIONS_SUCCESS';
export const SALES_MATERIAL_FACT_QUESTIONS_FAILURE = 'SALES_MATERIAL_FACT_QUESTIONS_FAILURE';

export const SALES_MATERIAL_FACT_ANSWER_QUESTION = 'SALES_MATERIAL_FACT_ANSWER_QUESTION';
export const SALES_MATERIAL_FACT_SET_DETAILS = 'SALES_MATERIAL_FACT_SET_DETAILS';
export const SALES_MATERIAL_FACT_UPDATE_DETAILS = 'SALES_MATERIAL_FACT_UPDATE_DETAILS';

export const SALES_QLD_SET_MATERIAL_FACT_QUESTIONS = 'SALES_QLD_SET_MATERIAL_FACT_QUESTIONS';
export const ADD_NEW_CLIENT_MATERIAL_FACT = 'ADD_NEW_CLIENT_MATERIAL_FACT';
export const DELETE_CLIENT_MATERIAL_FACT = 'DELETE_CLIENT_MATERIAL_FACT';

export const SALES_INSURANCE_QUESTIONS_REQUEST = 'SALES_INSURANCE_QUESTIONS_REQUEST';
export const SALES_INSURANCE_QUESTIONS_SUCCESS = 'SALES_INSURANCE_QUESTIONS_SUCCESS';
export const SALES_INSURANCE_QUESTIONS_FAILURE = 'SALES_INSURANCE_QUESTIONS_FAILURE';

export const SALES_INSURANCE_ANSWER_QUESTION = 'SALES_INSURANCE_ANSWER_QUESTION';
export const SALES_INSURANCE_SET_DETAILS = 'SALES_INSURANCE_SET_DETAILS';
export const SALES_INSURANCE_UPDATE_DETAILS = 'SALES_INSURANCE_UPDATE_DETAILS';
export const SALES_INSURANCE_UPDATE_DETAILS_LIST = 'SALES_INSURANCE_UPDATE_DETAILS_LIST';

export const SALES_OUTGOINGS_QUESTIONS_REQUEST = 'SALES_OUTGOINGS_QUESTIONS_REQUEST';
export const SALES_OUTGOINGS_QUESTIONS_SUCCESS = 'SALES_OUTGOINGS_QUESTIONS_SUCCESS';
export const SALES_OUTGOINGS_QUESTIONS_FAILURE = 'SALES_OUTGOINGS_QUESTIONS_FAILURE';

export const SALES_OUTGOING_ANSWER_QUESTION = 'SALES_OUTGOING_ANSWER_QUESTION';
export const SALES_OUTGOING_SET_DETAILS = 'SALES_OUTGOING_SET_DETAILS';
export const SALES_OUTGOING_UPDATE_DETAILS = 'SALES_OUTGOING_UPDATE_DETAILS';

export const ADD_NEW_CLIENT_OUTGOING = 'ADD_NEW_CLIENT_OUTGOING';
export const DELETE_CLIENT_OUTGOING = 'DELETE_CLIENT_OUTGOING';

const initialState = {
    salesMaterialFactAnswers: {},
    salesMaterialFactAnswered: false,
    salesInsuranceAnswers: {},
    salesInsuranceAnswered: false,
    salesOutgoingsAnswers: {},
    salesOutgoingsAnswered: false,
    ownerImagePreview: '',
};

function _findQuestion(questionId, answers, isClientAddedItems) {
    return find(isClientAddedItems ? answers.clientAddedItems : answers.questions, function (question) {
        return question.questionId === questionId;
    });
}

function _validateQuestionComplete(parent, currentValue, checkDetails = true) {
    // if question has a parent, it doesn't need to check if the answer of parent is not true
    if (parent) {
        if (parent.answer !== true) {
            return true;
        }
    }

    if ('answer' in currentValue) {
        if (!checkDetails) {
            return true;
        } else if (currentValue.detailsRequired) {
            if (currentValue.answer === true) {
                if (isEmpty(currentValue.detailsList)) {
                    if (currentValue.details && currentValue.detailsConfirmed) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    let allRequiredItemsFilled = true;
                    currentValue.detailsList.forEach((detail) => {
                        if (detail.detailsRequired && !detail.details) {
                            allRequiredItemsFilled = false;
                        }
                    });
                    return allRequiredItemsFilled && currentValue.detailsConfirmed;
                }
            } else {
                return true;
            }
        } else if (currentValue.detailsRequiredOnNo) {
            if (currentValue.answer === false) {
                if (currentValue.details && currentValue.detailsConfirmed) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    } else if ('visible' in currentValue) {
        if (!currentValue.visible) {
            return true;
        }
    }
    return false;
}

function _validateClientQuestionComplete(currentValue) {
    if (currentValue.detailsRequired) {
        if (currentValue.detailsOnly) {
            return currentValue.details && currentValue.detailsConfirmed;
        } else {
            return currentValue.details && currentValue.questionText && currentValue.detailsConfirmed;
        }
    }
    return true;
}

function _checkAllQuestionsAnswered(answers) {
    const answeredAllQuestions = answers.questions.every(function hasBeenAnswered(currentValue) {
        const parent = _findQuestion(currentValue.questionParent, answers);
        return _validateQuestionComplete(parent, currentValue);
    });
    let answeredAllClientQuestions = true;
    if (answers.isClientAddedItems) {
        answeredAllClientQuestions = answers.clientAddedItems.every(function hasBeenAnswered(currentValue) {
            return _validateClientQuestionComplete(currentValue);
        });
    }
    return answeredAllQuestions && answeredAllClientQuestions;
}

export default function salesQuestions(state = initialState, action) {
    let newState = cloneDeep(state);

    switch (action.type) {
        case SALES_MATERIAL_FACT_QUESTIONS_SUCCESS: {
            newState.salesMaterialFactAnswers = action.result.data.questions;
            return newState;
        }
        case SALES_QLD_SET_MATERIAL_FACT_QUESTIONS: {
            const salesMaterialFactAnswers = [];
            action.payload.materialFacts.clauseList.forEach((materialFact, index) => {
                salesMaterialFactAnswers.push({
                    questionId: getId(materialFact),
                    title: materialFact.title,
                    questionText: materialFact.description,
                    optionType: materialFact.optionType,
                });
            });
            if (action.payload.materialFacts.askClientToDisclose) {
                salesMaterialFactAnswers.push({
                    questionId: 'additional_material_facts',
                    questionText:
                        'Do you have any additional material facts associated with the property that needs to be disclosed? ',
                });
                newState.salesMaterialFactAnswers.clientAddedItems = [];
            }
            newState.salesMaterialFactAnswered = salesMaterialFactAnswers.length === 0;
            newState.salesMaterialFactAnswers.questions = salesMaterialFactAnswers;
            return newState;
        }

        case SALES_MATERIAL_FACT_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesMaterialFactAnswers);
            questionToAnswer.answer = answer;
            questionToAnswer.detailsConfirmed = false;
            questionToAnswer.details = '';

            if (questionToAnswer.questionId === 'additional_material_facts') {
                if (answer === true) {
                    newState.salesMaterialFactAnswers.clientAddedItems = [
                        {
                            details: '',
                            detailsRequired: true,
                            questionId: `client-material-fact-${Date.now()}`,
                            detailsOnly: true,
                        },
                    ];
                } else {
                    newState.salesMaterialFactAnswers.clientAddedItems = [];
                }
                newState.salesMaterialFactAnswers.isClientAddedItems = answer;
            }
            newState.salesMaterialFactAnswered = _checkAllQuestionsAnswered(newState.salesMaterialFactAnswers);

            return newState;
        }

        case SALES_MATERIAL_FACT_SET_DETAILS: {
            const { questionId, isClientAddedItems } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesMaterialFactAnswers, isClientAddedItems);
            questionToAnswer.detailsConfirmed = true;
            newState.salesMaterialFactAnswered = _checkAllQuestionsAnswered(newState.salesMaterialFactAnswers);

            return newState;
        }

        case SALES_MATERIAL_FACT_UPDATE_DETAILS: {
            const { details, questionId, isClientAddedItems } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesMaterialFactAnswers, isClientAddedItems);
            questionToAnswer.details = details;
            questionToAnswer.detailsConfirmed = false;
            newState.salesMaterialFactAnswered = _checkAllQuestionsAnswered(newState.salesMaterialFactAnswers);

            return newState;
        }

        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            newState.salesMaterialFactAnswered = false;
            if (action.result.data.user.ownerImage) {
                newState.ownerImagePreview = action.result.data.user.ownerImage.base64Image;
            }
            return newState;
        }
        case ADD_NEW_CLIENT_MATERIAL_FACT: {
            newState.salesMaterialFactAnswers.clientAddedItems.push({
                details: '',
                detailsRequired: true,
                questionId: `client-material-fact-${Date.now()}`,
                detailsOnly: true,
            });
            newState.salesMaterialFactAnswered = false;
            return newState;
        }
        case DELETE_CLIENT_MATERIAL_FACT: {
            newState.salesMaterialFactAnswers.clientAddedItems = [
                ...newState.salesMaterialFactAnswers.clientAddedItems.slice(0, action.index),
                ...newState.salesMaterialFactAnswers.clientAddedItems.slice(action.index + 1),
            ];
            newState.salesMaterialFactAnswered = _checkAllQuestionsAnswered(newState.salesMaterialFactAnswers);
            return newState;
        }

        case SALES_INSURANCE_QUESTIONS_SUCCESS: {
            newState.salesInsuranceAnswers = action.result.data.questions;
            return newState;
        }

        case SALES_INSURANCE_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesInsuranceAnswers);
            questionToAnswer.answer = answer;
            questionToAnswer.detailsConfirmed = false;
            questionToAnswer.details = '';

            newState.salesInsuranceAnswered = _checkAllQuestionsAnswered(newState.salesInsuranceAnswers);

            return newState;
        }

        case SALES_INSURANCE_SET_DETAILS: {
            const { questionId } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesInsuranceAnswers);
            questionToAnswer.detailsConfirmed = true;
            newState.salesInsuranceAnswered = _checkAllQuestionsAnswered(newState.salesInsuranceAnswers);

            return newState;
        }

        case SALES_INSURANCE_UPDATE_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesInsuranceAnswers);
            questionToAnswer.details = details;
            questionToAnswer.detailsConfirmed = false;
            newState.salesInsuranceAnswered = _checkAllQuestionsAnswered(newState.salesInsuranceAnswers);

            return newState;
        }
        case SALES_INSURANCE_UPDATE_DETAILS_LIST: {
            const { details, questionId, index } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesInsuranceAnswers);
            questionToAnswer.detailsList[index].details = details;
            questionToAnswer.detailsConfirmed = false;
            newState.salesInsuranceAnswered = _checkAllQuestionsAnswered(newState.salesInsuranceAnswers);

            return newState;
        }

        case SALES_OUTGOINGS_QUESTIONS_SUCCESS: {
            newState.salesOutgoingsAnswers = action.result.data.questions;
            newState.salesOutgoingsAnswers.clientAddedItems = [];
            return newState;
        }

        case SALES_OUTGOING_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesOutgoingsAnswers);
            questionToAnswer.answer = answer;
            questionToAnswer.detailsConfirmed = false;
            questionToAnswer.details = '';

            newState.salesOutgoingsAnswered = _checkAllQuestionsAnswered(newState.salesOutgoingsAnswers);

            return newState;
        }

        case SALES_OUTGOING_SET_DETAILS: {
            const { questionId, isClientAddedItems } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesOutgoingsAnswers, isClientAddedItems);
            questionToAnswer.detailsConfirmed = true;
            newState.salesOutgoingsAnswered = _checkAllQuestionsAnswered(newState.salesOutgoingsAnswers);

            return newState;
        }

        case SALES_OUTGOING_UPDATE_DETAILS: {
            const { details, questionId, isClientAddedItems, isQuestionText } = action;
            const questionToAnswer = _findQuestion(questionId, newState.salesOutgoingsAnswers, isClientAddedItems);
            if (isQuestionText) {
                questionToAnswer.questionText = details;
            } else {
                questionToAnswer.details = details;
            }
            questionToAnswer.detailsConfirmed = false;
            newState.salesOutgoingsAnswered = _checkAllQuestionsAnswered(newState.salesOutgoingsAnswers);

            return newState;
        }

        case ADD_NEW_CLIENT_OUTGOING: {
            newState.salesOutgoingsAnswers.clientAddedItems.push({
                questionText: '',
                details: '',
                detailsRequired: true,
                questionId: `client-outgoing-${Date.now()}`,
                detailsOnly: false,
            });
            newState.salesOutgoingsAnswers.isClientAddedItems = true;
            newState.salesOutgoingsAnswered = false;
            return newState;
        }
        case DELETE_CLIENT_OUTGOING: {
            newState.salesOutgoingsAnswers.clientAddedItems = [
                ...newState.salesOutgoingsAnswers.clientAddedItems.slice(0, action.index),
                ...newState.salesOutgoingsAnswers.clientAddedItems.slice(action.index + 1),
            ];
            newState.salesOutgoingsAnswered = _checkAllQuestionsAnswered(newState.salesOutgoingsAnswers);
            return newState;
        }
    }
    return newState;
}
