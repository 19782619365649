import 'sass/footer.scss';
import { AGENT_CONDITIONS_STEP } from 'common/constants';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import hashHistory from './../history';
import { isLocationBaseURL } from 'utils';

import Button, { ButtonStyle } from 'common/components/Button';
import ConfirmLocationFooter from 'containers/steps/ConfirmLocationFooter';
import SalesSigningFooter from 'containers/sales_steps/SalesSigningFooter';
import ReadAndAgreeFooter from 'containers/steps/ReadAndAgreeFooter';
import * as tenantLogin from 'actions/main';
import SkipQuestionsFooter from 'containers/common/SkipQuestionsFooter';
import QuestionsSignFooter from 'containers/common/QuestionsSignFooter';
import QuestionsFooter from '../containers/common/QuestionsFooter';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../utils';
import ChevronRight from '@flk-mui-icons/ChevronRight';

class SalesFooter extends Component {
    footerContent() {
        const {
            steps,
            step,
            currentStepStatus,
            isConfirmingStep,
            agentAdditionalTermsAnswers,
            agentAdditionalTermsAnswered,
            salesMaterialFactAnswered,
            salesMaterialFactAnswers,
            salesInsuranceAnswered,
            salesInsuranceAnswers,
            salesOutgoingsAnswers,
            salesOutgoingsAnswered,
            user,
        } = this.props;
        let currentStep = steps[step];

        let neverDefaultFooter = [
            'welcome', // Always agree to terms
            'summary', // Final page
            'woohoo',
            'pause',
        ];

        let defaultFooter = (
            <footer className={currentStepStatus}>
                {currentStepStatus === 'declined' && <div className="button button-cancel" />}
                {currentStepStatus === 'declined' && <div className="button button-success" />}
                {currentStepStatus === 'none' && (
                    <div className="button button-cancel">
                        <button onClick={() => this.props.declineButtonHandler()} disabled={isConfirmingStep}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
                {currentStepStatus === 'none' && (
                    <div className="button button-success">
                        <button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
                {currentStepStatus !== 'none' && currentStepStatus !== 'declined' && (
                    <div className="button button-prev">
                        <button onClick={() => this.toStep(step - 1, steps)}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
                {currentStepStatus !== 'none' && currentStepStatus !== 'declined' && (
                    <div className="button button-next">
                        <button onClick={() => this.toStep(step + 1, steps)}>
                            <div className="icon" />
                        </button>
                    </div>
                )}
            </footer>
        );

        if (currentStep.status === 'confirmed' && neverDefaultFooter.indexOf(currentStep.name) === -1) {
            return defaultFooter;
        }

        switch (currentStep.name) {
            case 'welcome': {
                if (isLeaseLocationQLD(this.props.location)) {
                    return (
                        <div className="footer-buttons footer-buttons--welcome-page">
                            <Button
                                className="footer-buttons__button"
                                onClick={() => this.props.confirmButtonHandler()}
                                disabled={isConfirmingStep}
                                buttonStyle={ButtonStyle.CARD}
                            >
                                Get Started <ChevronRight className="footer-buttons__button__icon" />
                            </Button>
                            <div className="footer-buttons__contact-me-text">
                                <p>
                                    Please click on the info icon in the top right if you need to contact me for
                                    assistance.
                                </p>
                                <p>Regards, {this.props.lease.agent.fullName}</p>
                            </div>
                        </div>
                    );
                }
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            Get Started
                        </Button>
                        <p>
                            By clicking "Get Started" you agree to FLK IT OVER'S{' '}
                            <a
                                href="http://www.flkitover.com/terms-conditions/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                terms and conditions
                            </a>{' '}
                            and{' '}
                            <a
                                href="http://www.flkitover.com/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                privacy policy
                            </a>
                        </p>
                    </div>
                );
            }
            case 'signing_location': {
                return (
                    <ConfirmLocationFooter
                        confirmButtonHandler={this.props.confirmButtonHandler}
                        enterLocationManually={this.props.enterLocationManually}
                    />
                );
            }
            case AGENT_CONDITIONS_STEP: {
                if (isLeaseLocationQLD(this.props.location)) {
                    if (user.isPrimaryVendor && agentAdditionalTermsAnswered) {
                        return (
                            <ReadAndAgreeFooter
                                step={step}
                                steps={steps}
                                currentStep={currentStep}
                                confirmButtonHandler={(data) =>
                                    this.props.confirmButtonHandler({
                                        ...data,
                                        answers: agentAdditionalTermsAnswers,
                                    })
                                }
                            />
                        );
                    } else if (!user.isPrimaryVendor) {
                        return <SkipQuestionsFooter currentStep={currentStep} />;
                    }
                } else if (isLeaseLocationNSW(this.props.location)) {
                    if (user.isPrimaryVendor && agentAdditionalTermsAnswers.questions.length > 0) {
                        return (
                            <QuestionsSignFooter
                                step={step}
                                steps={steps}
                                currentStep={currentStep}
                                answers={agentAdditionalTermsAnswers}
                                isConfirmingStep={isConfirmingStep}
                                confirmButtonHandler={this.props.confirmButtonHandler}
                                allAnswered={agentAdditionalTermsAnswered}
                                title={'Confirm agents additional terms'}
                                subtitle={''}
                                label={'Sign this'}
                            />
                        );
                    } else {
                        return <SkipQuestionsFooter currentStep={currentStep} />;
                    }
                }
                return null;
            }
            case 'marketing': {
                if (isLeaseLocationQLD(this.props.location)) {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Advertising/Marketing - I agree
                            </Button>
                        </div>
                    );
                }
                return null;
            }
            case 'fees_and_charges': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            Fees/Charges - I agree
                        </Button>
                    </div>
                );
            }
            case 'rebates': {
                if (isLeaseLocationQLD(this.props.location)) {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                Rebates - I agree
                            </Button>
                        </div>
                    );
                }
                return null;
            }
            case 'warning': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            I agree
                        </Button>
                    </div>
                );
            }
            case 'managing_agent': {
                if (this.props.lease.managingAgent.hasManagingAgent) {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                I agree
                            </Button>
                        </div>
                    );
                }
                return defaultFooter;
            }
            case 'auction_details': {
                if (this.props.lease.term.publicAuction === 'Yes') {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                I agree
                            </Button>
                        </div>
                    );
                }
                return defaultFooter;
            }
            case 'insurance': {
                if (user.isPrimaryVendor) {
                    return (
                        <QuestionsFooter
                            location={this.props.location}
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={salesInsuranceAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={salesInsuranceAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'outgoings': {
                if (user.isPrimaryVendor) {
                    return (
                        <QuestionsFooter
                            location={this.props.location}
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={salesOutgoingsAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={salesOutgoingsAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'owner_id':
            case 'terms': {
                return (
                    <footer className={currentStepStatus}>
                        {currentStepStatus !== 'none' && (
                            <div className="button button-prev">
                                <button onClick={() => this.toStep(step - 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                        {currentStepStatus !== 'none' && (
                            <div className="button button-next">
                                <button onClick={() => this.toStep(step + 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                    </footer>
                );
            }
            case 'termination_of_appointment': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            I agree
                        </Button>
                    </div>
                );
            }
            case 'commission': {
                return (
                    <div className="footer-buttons">
                        <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                            Commissions - I agree
                        </Button>
                    </div>
                );
            }
            case 'material_fact': {
                if (user.isPrimaryVendor) {
                    return (
                        <QuestionsFooter
                            location={this.props.location}
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={salesMaterialFactAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={salesMaterialFactAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'owner_signs': {
                return (
                    <SalesSigningFooter
                        step={step}
                        steps={steps}
                        isConfirmingStep={isConfirmingStep}
                        confirmButtonHandler={this.props.confirmButtonHandler}
                    />
                );
            }
            case 'standard_terms_conditions': {
                if (
                    isLeaseLocationQLD(this.props.location) &&
                    this.props.lease.specialConditions.hasSpecialConditions &&
                    user.isPrimaryVendor
                ) {
                    return (
                        <div className="footer-buttons">
                            <Button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                I have read and agree to this
                            </Button>
                        </div>
                    );
                }
                return (
                    <ReadAndAgreeFooter
                        step={step}
                        steps={steps}
                        currentStep={currentStep}
                        confirmButtonHandler={this.props.confirmButtonHandler}
                    />
                );
            }
            case 'summary': {
                let preloader = '';
                let disableButton = false;
                return (
                    <div className="footer-buttons">
                        <Button
                            onClick={() => this.props.confirmButtonHandler()}
                            disabled={disableButton || isConfirmingStep}
                        >
                            FLK it back to your agent {preloader}
                        </Button>
                    </div>
                );
            }
            case 'woohoo':
            case 'pause': {
                return <footer />;
            }
            default: {
                return defaultFooter;
            }
        }
    }

    toStep(step, steps) {
        if (isLocationBaseURL(this.props.lease.leaseType, this.props.location)) {
            hashHistory.push(`/sales-steps/${this.props.location}/${steps[step].name}`);
        } else {
            hashHistory.push(`/sales-steps/${steps[step].name}`);
        }
        this.props.setCurrentStep(steps[step], this.props.lease);
        window.scrollTo(0, 0);
    }

    render() {
        const appIsLoadingClass = this.props.appIsLoading ? 'hide' : '';
        return <div className={appIsLoadingClass}>{this.footerContent()}</div>;
    }
}

export default connect(
    (state) => ({
        stepDataLocal: state.main.steps,
        location: state.main.lease.location,
        isConfirmingStep: state.steps.isConfirmingStep,
        lease: state.main.lease,
        salesMaterialFactAnswers: state.salesQuestions.salesMaterialFactAnswers,
        salesMaterialFactAnswered: state.salesQuestions.salesMaterialFactAnswered,
        agentAdditionalTermsAnswers: state.commonQuestions.agentAdditionalTermsAnswers,
        agentAdditionalTermsAnswered: state.commonQuestions.agentAdditionalTermsAnswered,
        salesInsuranceAnswers: state.salesQuestions.salesInsuranceAnswers,
        salesInsuranceAnswered: state.salesQuestions.salesInsuranceAnswered,
        salesOutgoingsAnswers: state.salesQuestions.salesOutgoingsAnswers,
        salesOutgoingsAnswered: state.salesQuestions.salesOutgoingsAnswered,
        appIsLoading: state.main.appIsLoading,
        user: state.main.user,
    }),
    {
        setCurrentStep: tenantLogin.setCurrentStep,
    }
)(SalesFooter);
